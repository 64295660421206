import axios from "axios";
import { userSession } from "../components/security/cognito";
import { AuthStore } from "../store/authStore";

const URL =
  process.env.REACT_APP_ENV === "production"
    ? "https://api.metatranslator.ai/at-service"
    : "https://api.translator-seo-dev.belvy.io/at-service";
//  "http://127.0.0.1:5002/at-service";

const headers = () => {
  return {
    Authorization: `Bearer ${AuthStore.session.getIdToken().getJwtToken()}`,
  };
};

axios.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    let originalConfig = err.config;
    if (err.response.status === 401) {
      if (AuthStore.authenticated && AuthStore.session != null) {
        try {
          originalConfig._retry = true;
          const session = await userSession(AuthStore.session);
          AuthStore.interceptorSetSession(session);
          originalConfig.headers = headers();
          originalConfig.headers["content-type"] = "application/json";
          return axios(originalConfig);
        } catch (e) {
          return Promise.reject(e);
        }
      }
    } else {
      return Promise.reject(err);
    }
  }
);

export async function getOrganizations() {
  return (await axios.get(`${URL}/organization`, { headers: headers() })).data;
}

export async function getOrganization(id) {
  return (await axios.get(`${URL}/organization/${id}`, { headers: headers() }))
    .data;
}

export async function createOrganization(name) {
  const data = {
    name,
  };
  return (await axios.post(`${URL}/organization`, data, { headers: headers() }))
    .data;
}

export async function updateOrganization(id, name) {
  const data = {
    name,
  };
  return (
    await axios.put(`${URL}/organization/${id}`, data, { headers: headers() })
  ).data;
}

export async function getUser() {
  return (await axios.get(`${URL}/user`, { headers: headers() })).data;
}

export async function getUsers() {
  return (await axios.get(`${URL}/users`, { headers: headers() })).data;
}

export async function getUserById(userId) {
  return (await axios.get(`${URL}/user/${userId}`, { headers: headers() }))
    .data;
}

export async function getProjects() {
  return (await axios.get(`${URL}/project`, { headers: headers() })).data;
}

export async function getProject(id) {
  return (await axios.get(`${URL}/project/${id}`, { headers: headers() })).data;
}

export async function deleteProject(projectId) {
  return (
    await axios.delete(`${URL}/project/${projectId}`, { headers: headers() })
  ).data;
}

export async function createProject(name, description) {
  const data = {
    name,
    description,
  };
  return (await axios.post(`${URL}/project`, data, { headers: headers() }))
    .data;
}

export async function getUrls(projectId) {
  return (
    await axios.get(`${URL}/project/${projectId}/url`, { headers: headers() })
  ).data;
}

export async function getUrl(urlId) {
  return (await axios.get(`${URL}/url/${urlId}`, { headers: headers() })).data;
}

export async function getSubUrls(projectId, urlId) {
  return (
    await axios.get(`${URL}/project/${projectId}/url?base_url_id=${urlId}`, {
      headers: headers(),
    })
  ).data.sort((x) => x.id);
}

export async function retry(projectId, urlId) {
  return (
    await axios.get(
      `${URL}/project/${projectId}/url?base_url_id=${urlId}&retry=true`,
      { headers: headers() }
    )
  ).data;
}

export async function getSubUrlsText(projectId, urlId) {
  return (
    await axios.get(`${URL}/project/${projectId}/url/${urlId}/text`, {
      headers: headers(),
    })
  ).data.sort((x) => x.id);
}

export async function deleteUrl(urlId) {
  return (await axios.delete(`${URL}/url/${urlId}`, { headers: headers() }))
    .data;
}

export async function deleteText(textId) {
  return (await axios.delete(`${URL}/text/${textId}`, { headers: headers() }))
    .data;
}

export async function createText(projectId, urlId, text) {
  const data = {
    text,
  };
  return (
    await axios.post(`${URL}/project/${projectId}/url/${urlId}/text`, data, {
      headers: headers(),
    })
  ).data;
}

export async function updateText(textId, data) {
  return (
    await axios.put(`${URL}/text/${textId}`, data, {
      headers: headers(),
    })
  ).data;
}

export async function createUrl(
  projectId,
  url,
  alternate_url = false,
  parent_url_id = null,
  language = null
) {
  const data = {
    url,
    alternate_url,
    parent_url_id,
    language,
  };

  return (
    await axios.post(`${URL}/project/${projectId}/url`, data, {
      headers: headers(),
    })
  ).data;
}

export async function inviteUser(email, firstname, lastname, role, orgId) {
  const data = {
    firstname,
    email,
    username: email,
    lastname,
    role,
    org_id: orgId,
  };
  return (await axios.post(`${URL}/user`, data, { headers: headers() })).data;
}

export async function updateUser(
  email,
  firstname,
  lastname,
  role,
  resend = false
) {
  const data = {
    firstname,
    email,
    username: email,
    lastname,
    role,
  };
  const params = {};
  if (resend) {
    params["resend"] = true;
  }
  return (
    await axios.put(`${URL}/user`, data, { headers: headers(), params: params })
  ).data;
}

export async function updateProject(projectId, name, description, ownerId) {
  const data = { owner_id: ownerId, name, description };
  return (
    await axios.put(`${URL}/project/${projectId}`, data, {
      headers: headers(),
    })
  ).data;
}

export async function setProjectUsers(projectId, userIds) {
  const data = { user_ids: userIds };
  return (
    await axios.put(`${URL}/project/${projectId}/user`, data, {
      headers: headers(),
    })
  ).data;
}

export async function translation(text, targetCode) {
  const data = {
    text,
    target_code: targetCode,
  };
  return (
    await axios.post(`${URL}/translate`, data, {
      headers: headers(),
    })
  ).data;
}

export async function getAllSupportedLanguage() {
  return (await axios.get(`${URL}/supported-languages`, { headers: headers() }))
    .data;
}

export async function configureLanguage(projectId, data) {
  return (
    await axios.post(`${URL}/configure-languages/${projectId}`, data, {
      headers: headers(),
    })
  ).data;
}

export async function getConfiguredLanguage(projectId) {
  return (
    await axios.get(`${URL}/configure-languages/${projectId}`, {
      headers: headers(),
    })
  ).data;
}

export async function omitwords(projectId, phrases) {
  const data = {
    phrases,
  };
  return (
    await axios.post(`${URL}/omit-words/${projectId}`, data, {
      headers: headers(),
    })
  ).data;
}

export async function getomitwords(projectId) {
  return (
    await axios.get(`${URL}/omit-words/${projectId}`, { headers: headers() })
  ).data;
}

export async function manualTranslate(urlId, textId, language) {
  let url = `${URL}/manual-translate/${urlId}/text/${textId}?language=${language}`;
  return (await axios.post(url, {}, { headers: headers() })).data;
}

export async function manualTranslateAll(urlId, language, data) {
  let url = `${URL}/manual-translate/${urlId}?language=${language}`;
  return (await axios.post(url, data, { headers: headers() })).data;
}


export async function updateUrlLocale(urlId, data) {
  return (await axios.put(`${URL}/url/${urlId}`, data, { headers: headers() }))
    .data;
}