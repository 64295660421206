import "./App.css";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import Login from "./components/security/Login";
// import Signup from "./components/security/Signup";
import Logout from "./components/security/Logout";
import ForgotPassword from "./components/security/ForgotPassword";
import RequiredAuth from "./components/security/RequiredAuth";
import User from "./components/security/User";
import Organization from "./components/security/Organization";
import AddUser from "./components/security/AddUser";
import AddOrganization from "./components/security/AddOrganization";
import AddProject from "./components/project/AddProject";
import ProjectDetail from "./components/project/ProjectDetail";
import ProjectPermission from "./components/project/ProjectPermission";
import Home from "./view/Home";

import { getUser } from "./utils/api";

import { observer } from "mobx-react-lite";

import { Layout, Menu } from "antd";
import { useCallback, useEffect } from "react";
import ConfigureLanguage from "./components/project/ConfigureLanguage";
import OmitWords from "./components/project/OmitWords";
import SubURLDetail from "./components/project/SubURLDetail";

const { Header, Content, Footer } = Layout;

function App({ authStore, navStore, projectStore }) {
  const fetchData = useCallback(async () => {
    const user = await getUser();
    authStore.updateUser(user);
  }, [authStore]);

  useEffect(() => {
    if (authStore.session !== null) {
      fetchData();
    }
  }, [authStore.session, fetchData]);

  return (
    <div className="App">
      <Layout>
        <Header>
          <div className="App__logo">
            <img src={"/bandt-logo.png"} alt="Block and Tam Translate" />
            <h1>Meta Translator</h1>
          </div>
          <div>
            {authStore.authenticated && (
              <Menu
                align="right"
                mode="horizontal"
                selectedKeys={[navStore.selectedKey]}
              >
                <Menu.Item key="4">
                  <a href="/">Projects</a>
                </Menu.Item>

                {["admin"].indexOf(authStore.userRole) >= 0 && (
                  <Menu.Item key="6">
                    <a href="/organization">Organizations</a>
                  </Menu.Item>
                )}

                {["org_admin", "admin"].indexOf(authStore.userRole) >= 0 && (
                  <Menu.Item key="3">
                    <a href="/user">Users</a>
                  </Menu.Item>
                )}

                <Menu.Item key="5">
                  <a href="/logout">Logout</a>
                </Menu.Item>
              </Menu>
            )}
            {!authStore.authenticated && (
              <Menu
                align="right"
                mode="horizontal"
                selectedKeys={[navStore.selectedKey]}
              >
                <Menu.Item key="11">
                  <a href="/login">Login</a>
                </Menu.Item>
                {/* <Menu.Item key="12">
                  <a href="/signup">Signup</a>
                </Menu.Item> */}
              </Menu>
            )}
          </div>
        </Header>

        <Content className="content">
          <BrowserRouter>
            <Routes>
              <Route
                path="/"
                element={
                  <RequiredAuth
                    authStore={authStore}
                    children={
                      <Home
                        authStore={authStore}
                        navStore={navStore}
                        projectStore={projectStore}
                      />
                    }
                  />
                }
              />
              <Route
                path="/project/add"
                element={
                  <RequiredAuth
                    authStore={authStore}
                    children={
                      <AddProject authStore={authStore} navStore={navStore} />
                    }
                  />
                }
              />
              <Route
                path="/project/:id"
                element={
                  <RequiredAuth
                    authStore={authStore}
                    children={
                      <ProjectDetail
                        authStore={authStore}
                        navStore={navStore}
                        projectStore={projectStore}
                      />
                    }
                  />
                }
              />
              <Route
                path="/project/:id/permission"
                element={
                  <RequiredAuth
                    authStore={authStore}
                    children={
                      <ProjectPermission
                        authStore={authStore}
                        navStore={navStore}
                      />
                    }
                  />
                }
              />

              <Route
                path="/project/:id/url/:urlId/text"
                element={
                  <RequiredAuth
                    authStore={authStore}
                    children={
                      <SubURLDetail
                        authStore={authStore}
                        navStore={navStore}
                        projectStore={projectStore}
                      />
                    }
                  />
                }
              />
              <Route
                path="/organization"
                element={
                  <RequiredAuth authStore={authStore}>
                    <Organization navStore={navStore} authStore={authStore} />
                  </RequiredAuth>
                }
              />

              <Route
                path="/organization/add"
                element={
                  <RequiredAuth authStore={authStore}>
                    <AddOrganization
                      navStore={navStore}
                      authStore={authStore}
                    />
                  </RequiredAuth>
                }
              />
              <Route
                path="/organization/edit/:id"
                element={
                  <RequiredAuth authStore={authStore}>
                    <AddOrganization
                      navStore={navStore}
                      authStore={authStore}
                    />
                  </RequiredAuth>
                }
              />
              <Route
                path="/user"
                element={
                  <RequiredAuth authStore={authStore}>
                    <User navStore={navStore} authStore={authStore} />
                  </RequiredAuth>
                }
              />
              <Route
                path="/user/add"
                element={
                  <RequiredAuth authStore={authStore}>
                    <AddUser navStore={navStore} authStore={authStore} />
                  </RequiredAuth>
                }
              />
              <Route
                path="/user/edit/:id"
                element={
                  <RequiredAuth authStore={authStore}>
                    <AddUser navStore={navStore} authStore={authStore} />
                  </RequiredAuth>
                }
              />
              <Route
                path="/login"
                element={<Login authStore={authStore} navStore={navStore} />}
              />
              {/* <Route
                path="/signup"
                element={<Signup authStore={authStore} navStore={navStore} />}
              /> */}
              <Route
                path="/logout"
                element={<Logout authStore={authStore} />}
              />
              <Route
                path="/forgot"
                element={<ForgotPassword authStore={authStore} />}
              />
              <Route
                path="/configure-language/project/:id"
                element={
                  <RequiredAuth
                    authStore={authStore}
                    children={<ConfigureLanguage authStore={authStore} />}
                  />
                }
              />
              <Route
                path="/omit-words/project/:id"
                element={
                  <RequiredAuth
                    authStore={authStore}
                    children={<OmitWords authStore={authStore} />}
                  />
                }
              />
            </Routes>
          </BrowserRouter>
        </Content>

        <Footer style={{ textAlign: "center" }}></Footer>
      </Layout>
    </div>
  );
}

export default observer(App);
