import React, { useCallback, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { LoadingOutlined, LeftOutlined } from "@ant-design/icons";
import { Button, Card, Spin, notification, Select } from "antd";
import { getProject, getUsers, updateProject, setProjectUsers } from "../../utils/api";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

import "./ProjectPermission.css";

const { Option } = Select;
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

function ProjectPermission({ authStore, navStore, params }) {
  const [loading, setLoading] = useState(true);
  const [project, setProject] = useState(null);
  const [users, setUsers] = useState(null);

  const navigate = useNavigate();

  const handleOwnerChange = async (user_id) => {
    await updateProject(project.id, project.name, project.description, user_id);
    fetchData();
    notification.open({
      message: "Project owner Changed",
      description: "Project owner changed successfully",
    });
  };

  const handleUserChange = async (userIds) => {
    console.log("user changing ");
    console.log(userIds);
    await setProjectUsers(project.id, userIds);
    fetchData();
    notification.open({
      message: "Project users changed",
      description: "Project users changed successfully",
    });
  };

  let { id } = useParams();

  const fetchData = useCallback(async () => {
    const projectDetail = await getProject(id);
    const users = await getUsers();
    setProject(projectDetail[0]);
    setUsers(users);
    setLoading(false);
  }, [id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <div className="ProjectPermission">
      <div>
        {loading ? (
          <Spin indicator={antIcon} />
        ) : (
          <div className="ProjectPermission">
            <div className="ProjectPermission__wrapper">
              <div className="ProjectPermission__container">
                <Button
                  className="ProjectPermission__container_back"
                  type="text"
                  onClick={() => navigate(-1)}
                >
                  <LeftOutlined /> Back
                </Button>

                <h2>{project.name} Permissions</h2>

                <Card
                  style={{
                    width: "100%",
                    margin: "auto",
                  }}
                >
                  <h4>Owner</h4>
                  <Select
                    mode="single"
                    allowClear={false}
                    style={{
                      width: "100%",
                    }}
                    placeholder="Please select"
                    defaultValue={project.owner_id}
                    onChange={handleOwnerChange}
                  >
                    {users.map((x) => (
                      <Option
                        key={x.id}
                        value={x.id}
                      >{`${x.firstname} (${x.email})`}</Option>
                    ))}
                  </Select>

                  <h4 className="mt-2">Users</h4>

                  <Select
                    mode="multiple"
                    allowClear
                    style={{
                      width: "100%",
                    }}
                    
                    placeholder="Please select"
                    defaultValue={project.users}
                    onChange={handleUserChange}
                  >
                    {users.map((x) => (
                      <Option
                        key={x.id}
                        value={x.id}
                      >{`${x.firstname} (${x.email})`}</Option>
                    ))}
                  </Select>
                </Card>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default observer(ProjectPermission);
