import React, { useCallback, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";

import {
  LoadingOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  RetweetOutlined,
  SettingOutlined,
  TranslationOutlined,
} from "@ant-design/icons";
import {
  Button,
  Table,
  Spin,
  Modal,
  Form,
  Input,
  notification,
  Dropdown,
  Menu,
  Select
} from "antd";
import {
  getSubUrlsText,
  getUrl,
  updateText,
  getProject,
  deleteText,
  getConfiguredLanguage,
  getAllSupportedLanguage,
  manualTranslate,
  manualTranslateAll,
  retry,
  updateUrlLocale,
} from "../../utils/api";
import { useParams } from "react-router-dom";

import "./URLDetail.css";
import { toJS } from "mobx";
import Translation from "./Translation";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const antIconWhite = (
  <LoadingOutlined style={{ color: "white", fontSize: 18 }} spin />
);
const { confirm } = Modal;
const { Option } = Select;

function SubURLDetail({ authStore, navStore, projectStore, params }) {
  const [submitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(true);
  const [polling, setPolling] = useState(false);
  const [baseUrl, setBasetUrl] = useState(null);
  const [project, setProject] = useState(toJS(projectStore.project));
  const [texts, setTexts] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);
  const [loadings, setLoadings] = useState([]);
  const [override, setOverride] = useState(null);
  const [overrideProjectId, setOverrideProjectId] = useState(null);
  const [overrideText, setOverrideText] = useState(null);
  const [overrideLoading, setOverrideLoading] = useState(false);
  const [editForm] = Form.useForm();
  const [buttonDisable, setButtonDisable] = useState(false);
  const [languages, setLanguages] = useState(
    toJS(projectStore.configLanguages)
  );
  const [defaultlanguage, setDefaultLanguage] = useState("");
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [translateLoading, setTranslateLoading] = useState(false);
  const [publishLoading, setPublishLoading] = useState(false);
  const [localeLoading, setLocaleLoading] = useState(false);
  const [locale, setLocale] = useState("");
  const [updateLocaleForm] = Form.useForm();

  let { id, urlId } = useParams();

  const navigate = useNavigate();

  const toggleLoading = (index) => {
    setLoadings((prevLoadings) => {
      const newLoadings = [...prevLoadings];
      newLoadings[index] = !newLoadings[index];
      return newLoadings;
    });
  };

  const showOverrideModal = (id, text, edit = false) => {
    editForm.setFieldsValue({
      text: edit ? text.overwrite_text : text.auto_translated_text,
    });
    setOverrideLoading(true);
    setOverrideText(text);
    setOverrideProjectId(id);
  };

  const onFinishUpdateOverride = async () => {
    setSubmitting(true);
    setButtonDisable(true);
    try {
      overrideText["overwrite_text"] = override;
      await updateText(overrideProjectId, overrideText);
      await fetchData();
      setOverride(null);
      setOverrideLoading(false);
    } catch (e) {
      notification.open({
        message: e.message ? e.message : "Error",
        description: e.response.data
          ? e.response.data.message
          : "Please try again",
      });
      setSubmitting(false);
    } finally {
      setSubmitting(false);
      setButtonDisable(false);
    }
  };

  const showLocaleModal = () => {
    updateLocaleForm.setFieldsValue({
      language: defaultlanguage,
    });
    setLocaleLoading(true);
  };

  const onFinishUpdateLocale = async () => {
    setSubmitting(true);
    setButtonDisable(true);
    try {
      let data = {url: baseUrl[0].url, language: locale}
      await updateUrlLocale(urlId, data);
      await fetchData();
      setLocale(null);
      setLocaleLoading(false);
    } catch (e) {
      notification.open({
        message: e.message ? e.message : "Error",
        description: e.response.data
          ? e.response.data.message
          : "Please try again",
      });
      setSubmitting(false);
    } finally {
      setSubmitting(false);
      setButtonDisable(false);
    }
  };

  const showOverrideButton = (record) => {
    return (
      <Button
        onClick={() => showOverrideModal(record.id, record)}
        disabled={buttonDisable || record.id ? false : true}
      >
        Override
      </Button>
    );
  };

  const onEditOverride = (record) => {
    setOverride(record.overwrite_text);
    showOverrideModal(record.id, record, true);
  };

  const showClearConfirm = (record) => {
    setOverride(null);
    confirm({
      title: "Are you sure you want to clear this text?",
      icon: <ExclamationCircleOutlined />,
      content: "",
      okText: "Clear",
      okType: "danger",
      cancelText: "Cancel",
      async onOk() {
        let data = record;
        data["overwrite_text"] = null;
        setButtonDisable(true);
        await updateText(record.id, data);
        await fetchData();
        setOverride(null);
        setButtonDisable(false);
      },
      onCancel() { },
    });
  };

  const showEditClear = (record) => {
    return (
      <span>
        <p>{record.overwrite_text}</p>
        <button
          className="link"
          onClick={() => onEditOverride(record)}
          disabled={buttonDisable}
        >
          Edit
        </button>{" "}
        &nbsp; | &nbsp;
        <button
          className="link"
          onClick={() => showClearConfirm(record)}
          disabled={buttonDisable}
        >
          Clear
        </button>
      </span>
    );
  };

  const menu = (
    <Menu
      onClick={(e) => {
        if (e.key === "crawl") {
          onCrawl();
        } else if (e.key === "translate-all") {
          onTranslateAll();
        }
      }}
      items={[
        {
          label: "Crawl",
          key: "crawl",
          icon: <RetweetOutlined />,
        },
        {
          label: "Translate all",
          key: "translate-all",
          icon: <TranslationOutlined />,
        },
      ]}
    />
  );

  const onSelectChange = (newSelectedRowKeys) => {
    console.log('selectedRowKeys changed: ', newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const hasSelected = selectedRowKeys.length > 0;

  const columns = [
    {
      title: "Element",
      key: "type",
      render: (_, record) => {
        return (
          <span>
            {Object.keys(record).length === 0
              ? ""
              : record.text_type !== null
                ? record.text_type
                : "Title"}
          </span>
        );
      },
      filters: [
        {
          text: 'Title',
          value: ['title'],
        },
        {
          text: 'Headers',
          value: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'],
        },
        {
          text: 'Meta Description',
          value: ['meta'],
        },
        {
          text: 'Hyperlinks',
          value: ['a', 'link'],
        },
        {
          text: 'Text',
          value: ['p', 'label', 'li'],
        },
        {
          text: 'Div',
          value: ['div'],
        },
        {
          text: 'Span',
          value: ['span'],
        },
      ],
      onFilter: (value, record) => value.includes(record.text_type)
    },
    {
      title: "Current Text",
      dataIndex: "text",
      key: "text",
      render: (_, record) => {
        return <span>{record.text}</span>;
      },
    },
    {
      title: "Recommended Text",
      dataIndex: "text",
      key: "text",
      render: (_, record) => {
        return (
          <span>
            {record.auto_translated_text ? (
              record.auto_translated_text
            ) : (
              <Button
                onClick={() =>
                  translate(project.id, record.id, baseUrl[0].language)
                }
              >
                Translate
              </Button>
            )}
          </span>
        );
      },
    },
    {
      title: "Override",
      dataIndex: "overwrite_text",
      key: "overwrite_text",
      render: (_, record) => {
        return (
          <span>
            {record.overwrite_text
              ? showEditClear(record)
              : showOverrideButton(record)}
          </span>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      sorter: (a, b) => a.status.localeCompare(b.status),
      render: (_, record) => {
        return (
          <Button
            onClick={() =>
              updateStatus(
                record.id,
                record.status === "draft" ? "published" : "draft",
                record
              )
            }
            loading={loadings[record.id]}
            disabled={buttonDisable || record.id ? false : true}
          >
            {record.status}
          </Button>
        );
      },
    },
    {
      title: "Actions",
      dataIndex: "action",
      key: "action",
      render: (_, record) => {
        return (
          <button
            className="link"
            onClick={() => showDeleteUrlConfirm(record.id)}
            disabled={buttonDisable}
          >
            <DeleteOutlined />
          </button>
        );
      },
    },
  ];

  const updateStatus = async (id, status, text) => {
    toggleLoading(id);
    setButtonDisable(true);
    text["status"] = status;
    await updateText(id, text);
    await fetchData();
    toggleLoading(id);
    setButtonDisable(false);
  };

  const showDeleteUrlConfirm = (id) => {
    confirm({
      title: `Are you sure you want to delete this text?`,
      icon: <ExclamationCircleOutlined />,
      content: "",
      okText: "Delete",
      okType: "danger",
      cancelText: "cancel",
      async onOk() {
        setButtonDisable(true);
        await deleteText(id);
        await fetchData();
        setButtonDisable(false);
      },
      onCancel() { },
    });
  };

  const translate = async (projectId, textId, language) => {
    await manualTranslate(projectId, textId, language);
    fetchData();
  };

  const fetchData = useCallback(async () => {
    const textsPromise = getSubUrlsText(id, urlId);
    const baseUrlPromise = getUrl(urlId);
    const projectPromise = getProject(id);
    const languages = getConfiguredLanguage(id);

    const texts = await textsPromise;
    const project_ = (await projectPromise)[0];
    const baseUrl = await baseUrlPromise;

    let configuredLanguages = await languages;
    setProject(project_);
    setBasetUrl(baseUrl);
    if (configuredLanguages.length === 0) {
      const allLanguages = getAllSupportedLanguage();
      configuredLanguages = await allLanguages;
      configuredLanguages["Languages"]?.map((data) => {
        delete Object.assign(data, { language_code: data.LanguageCode })[
          "LanguageCode"
        ];
        delete Object.assign(data, { language: data.LanguageName })[
          "LanguageName"
        ];
        return configuredLanguages;
      });
      configuredLanguages = configuredLanguages["Languages"];
    }
    let defaultlanguage = configuredLanguages.find(language => language.language_code === baseUrl[0].language) ? baseUrl[0].language : baseUrl[0].language.substring(0, 2)  
    setDefaultLanguage(defaultlanguage)
    setLanguages(configuredLanguages);
    const elements = [
      "title",
      "h1",
      "h2",
      "h3",
      "h4",
      "h5",
      "h6",
      "h7",
      "h8",
      "h9",
      "strong",
      "button",
      "li",
      "label",
      "option",
      "a",
      "span",
      "div",
      "p",
      "sup",
    ];
    const s = new Set();
    texts.sort((a, b) => {
      const aIndex = elements.indexOf(a.text_type);
      const bIndex = elements.indexOf(b.text_type);
      s.add(b.text_type);
      if (aIndex !== -1 && bIndex !== -1) {
        if (aIndex < bIndex) {
          return -1;
        } else {
          return 1;
        }
      } else if (aIndex !== -1) {
        return -1;
      } else {
        return -1;
      }
    });
    setTexts(texts);
    setLoading(false);
    setTableLoading(false);
  }, [id, urlId]);

  const onCrawl = async () => {
    setPolling(true);
    notification.open({
      message: "Crawling",
      description: "Crawling URL",
    });
    await retry(project.id, urlId);
    await fetchData();
    setPolling(false);
  };

  const onTranslateAll = async () => {
    notification.open({
      message: "Translating",
      description: "Translating all texts",
    });
    await manualTranslateAll(urlId, baseUrl[0].language, {});
    fetchData();
  };

  const onTranslateSelected = async () => {
    setTranslateLoading(true);
    notification.open({
      message: "Translating",
      description: "Translating selected texts",
    });
    await manualTranslateAll(urlId, baseUrl[0].language, {text_ids: selectedRowKeys});
    fetchData();
    setSelectedRowKeys([]);
    setTranslateLoading(false);
  };

  const onPublishSelected = async () => {
    setPublishLoading(true);
    notification.open({
      message: "Publishing",
      description: "Publishing selected texts",
    });
    let data = {
      status: "published",
      text_ids: selectedRowKeys
    }
    await updateText(id, data);
    fetchData();
    setSelectedRowKeys([]);
    setPublishLoading(false);
  };


  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <div className="URLDetail">
      <Modal
        visible={overrideLoading}
        title="Override SEO title"
        onCancel={() => {
          setOverrideLoading(false);
        }}
        footer={null}
      >
        <Form
          name="basic"
          initialValues={{ remember: false }}
          autoComplete="off"
          layout="vertical"
          form={editForm}
          onFinish={onFinishUpdateOverride}
        >
          <Form.Item
            className="AddProject__item"
            label="Title"
            name="text"
            rules={[{ required: true, message: "Please enter a title" }]}
          >
            <Input onChange={(e) => setOverride(e.target.value)} />
          </Form.Item>
          <Form.Item style={{ marginBottom: "0" }}>
            <div style={{ textAlign: "right" }}>
              <Button type="primary" htmlType="submit">
                {submitting && <Spin indicator={antIconWhite} />}
                {!submitting && <span> Submit </span>}
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        visible={localeLoading}
        title="Update Locale"
        onCancel={() => {
          setLocaleLoading(false);
        }}
        footer={null}
      >
        <Form
          name="basic"
          initialValues={{ remember: false }}
          autoComplete="off"
          layout="vertical"
          form={updateLocaleForm}
          onFinish={onFinishUpdateLocale}
        >
          <Form.Item
            className="AddProject__item"
            label="Locale"
            name="language"
            rules={[{ required: true, message: "Please select a Locale" }]}
          >
            <Select
                placeholder="Select Source Language"
                name="locale"
                onChange={(value) => setLocale(value)}
              >
                {languages?.map((data) => {
                  return (
                    <Option key={data.language_code} value={data.language_code}>
                      {data.language}
                    </Option>
                  );
                })}
              </Select>
          </Form.Item>
          <Form.Item style={{ marginBottom: "0" }}>
            <div style={{ textAlign: "right" }}>
              <Button type="primary" htmlType="submit">
                {submitting && <Spin indicator={antIconWhite} />}
                {!submitting && <span> Submit </span>}
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Modal>
      <div>
        {loading ? (
          <Spin indicator={antIcon} />
        ) : (
          <div>
            <h2 style={{ float: "left" }}>
              <button
                className="link"
                onClick={() => navigate(`/project/${project.id}`)}
              >
                {project.name}
              </button>{" "}
              &gt; URL: <u>{baseUrl[0].url}</u>
            </h2>
            <div className="Projects__headerbar">
              <Button onClick={showLocaleModal}>
                Locale:{defaultlanguage}
              </Button>&nbsp;
              <Dropdown overlay={menu}>
                <Button>
                  <SettingOutlined />
                </Button>
              </Dropdown>
            </div>

            <Translation
              defaultTarget={defaultlanguage}
              languages={languages}
            />
            {baseUrl[0].http_status_code != null ? (
              <>
              <div className="selectButtons">
                <Button onClick={onTranslateSelected} disabled={!hasSelected} loading={translateLoading}>
                  Translate
                </Button>&nbsp;
                <Button onClick={onPublishSelected} disabled={!hasSelected} loading={publishLoading}>
                  Publish
                </Button>
              </div>
                <Table
                  rowClassName={(record) => {
                    return record.status === "draft" ? "URLDetail__draft" : "";
                  }}
                  rowKey={(record) => record.id}
                  dataSource={texts}
                  columns={columns}
                  loading={tableLoading}
                  pagination={{ defaultPageSize: 50 }}
                  rowSelection={rowSelection}
                />
              </>
            ) : (
              <div
                style={{
                  clear: "both",
                  background: "white",
                  margin: "auto",
                  marginTop: "20px",
                  padding: "20px",
                  width: "500px",
                }}
              >
                <div style={{ marginBottom: "20px" }}>
                  Looks like the page hasn't been crawled.
                </div>
                <Button type="primary" block onClick={onCrawl}>
                  {polling && <Spin indicator={antIconWhite} />}
                  {!polling && <span>Crawl</span>}
                </Button>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default observer(SubURLDetail);
