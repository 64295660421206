import { observer } from "mobx-react-lite";
import { useCallback, useEffect, useState } from "react";
import { Form, Button, notification, Spin } from "antd";
import { LoadingOutlined, LeftOutlined } from "@ant-design/icons";
import { getomitwords, omitwords } from "../../utils/api";
import { useNavigate, useParams } from "react-router-dom";
import "./AddProject.css";
import TextArea from "antd/lib/input/TextArea";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const antIconWhite = (
  <LoadingOutlined style={{ color: "white", fontSize: 18 }} spin />
);

function OmitWords({ authStore }) {
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState("");
  const [phrases, setPhrases] = useState([]);
  const [form] = Form.useForm();
  let { id } = useParams();
  const navigate = useNavigate();

  const fetchData = useCallback(async () => {
    const words = await getomitwords(id);
    if (words.length > 0) {
      setPhrases(words[0].phrases);
      form.setFieldsValue({
        phrases: words[0].phrases.join("\n"),
      });
    }
    setLoading(false);
  }, [form, id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const onFinish = async () => {
    setError("");
    setSubmitting(true);
    try {
      await omitwords(id, phrases);
      notification.open({
        message: "Configure Omit phrases",
        description: "Successfully configured omit phrases",
      });
      navigate(-1);
    } catch (e) {
      setSubmitting(false);
      setError(e.response.data.message);
    } finally {
      setSubmitting(false);
    }
  };

  const splitAndSetPhrase = (e) => {
    const text = e.target.value
      .split("\n")
      .filter((x) => x.trim())
      .filter((x) => x.length > 0);
    setPhrases(text);
  };

  return (
    <div className="AddProject">
      <div className="m-2">
        {loading ? (
          <Spin indicator={antIcon} />
        ) : (
          <div className="AddProject__wrapper">
            <div className="AddProject__container">
              <Button
                className="ProjectPermission__container_back"
                type="text"
                onClick={() => navigate(-1)}
              >
                <LeftOutlined /> Back
              </Button>
              <h2>Configure omit phrases</h2>
              <p>Please enter words or phrases separated by line breaks</p>
              <Form
                name="basic"
                initialValues={{ remember: true }}
                autoComplete="off"
                layout="vertical"
                form={form}
                onFinish={onFinish}
              >
                <Form.Item
                  className="AddProject__item"
                  label="Phrases"
                  name="phrase"
                  rules={[{ required: false }]}
                >
                  <TextArea
                    placeholder="Phrases"
                    rows={5}
                    onChange={(e) => splitAndSetPhrase(e)}
                    defaultValue={phrases.join("\n")}
                  />
                </Form.Item>

                {error && <div className="error">{error}</div>}
                <Form.Item>
                  <Button type="primary" htmlType="submit" block>
                    {submitting && <Spin indicator={antIconWhite} />}
                    {!submitting && <span> Configure</span>}
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default observer(OmitWords);
