import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { Form, Input, Select } from "antd";
import { Col, Row } from "antd";
import debounce from "lodash.debounce";

import "./Translation.css";
import { translation } from "../../utils/api";

const { Option } = Select;

function Translation({ languages, defaultTarget }) {
  const [text, setText] = useState("");
  const [targetCode, setTargetCode] = useState("");
  const [form] = Form.useForm();


  let defaultValue = "";
  if (defaultTarget) {
    const exactMatch = languages.find((x) => x.language_code === defaultTarget);
    const languageMatch = languages.find(
      (x) => x.language_code === defaultTarget.split("-")[0]
    );
    if (exactMatch) {
      defaultValue = exactMatch.language_code;
    } else if (languageMatch) {
      defaultValue = languageMatch.language_code;
    }
  }

  useEffect(()=> {
    setTargetCode(defaultValue);
    form.setFieldsValue({
      sourceLanguage: "en",
      targetLanguage: defaultValue
    });
  }, [form, defaultValue]);

  const onChangeText = debounce(async (text, value = null) => {
    setText(text);
    let code = targetCode;
    if (value) {
      code = value;
    }
    const res = await translation(text, code);
    form.setFieldsValue({
      translation: res.TranslatedText,
    });
  }, 250);

  const handleChangeSource = (value) => {
    //setSourceCode(value);
  };

  const handleChangeTarget = (value) => {
    setTargetCode(value);
    onChangeText(text, value);
  };

  return (
    <div className="AddTranslation">
      <div className="AddTranslation_wrapper">
        <Form
          name="basic"
          initialValues={{ remember: true }}
          autoComplete="off"
          layout="vertical"
          form={form}
        >
          <Row>
            <Col span={6}>
              <Select
                placeholder="Select Source Language"
                defaultValue={"en"}
                name="sourceLanguage"
                style={{
                  width: "10vw",
                }}
                onChange={handleChangeSource}
              >
                {languages?.map((data) => {
                  return (
                    <Option key={data.language_code} value={data.language_code}>
                      {data.language}
                    </Option>
                  );
                })}
              </Select>
            </Col>
            <Col span={16}>
              <Form.Item
                className="AddTranslation__item"
                name="name"
                style={{
                  width: "100%",
                  marginLeft: "2em",
                  marginBottom: "0.5em",
                }}
                rules={[{ required: false }]}
              >
                <Input
                  placeholder="Enter text to transalate"
                  onChange={(e) => onChangeText(e.target.value)}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={6}>
              <Select
                placeholder="Select Target Language"
                defaultValue={defaultValue}
                name="targetLanguage"
                style={{
                  width: "10vw",
                }}
                onChange={handleChangeTarget}
              >
                {languages?.map((data) => {
                  return (
                    <Option key={data.language_code} value={data.language_code}>
                      {data.language}
                    </Option>
                  );
                })}
              </Select>
            </Col>
            <Col span={16}>
              <Form.Item
                className="AddTranslation__item"
                name="translation"
                style={{
                  width: "100%",
                  marginLeft: "2em",
                }}
                rules={[{ required: false }]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
}

export default observer(Translation);
