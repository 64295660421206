import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { Button, Table } from "antd";
import { getOrganizations } from "../../utils/api";
import { useNavigate } from "react-router-dom";
import { Spin, Space } from "antd";
import { LoadingOutlined, EditOutlined } from "@ant-design/icons";

import "./Organization.css";

const antIcon = <LoadingOutlined style={{ fontSize: 32 }} spin />;
function Organization({ navStore, authStore }) {
  const navigate = useNavigate();
  const [Organizations, setOrganizations] = useState([]);
  const [loading, setLoading] = useState(false);
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      render: (_, record) => (
        <Space>
          {<button className="link" onClick={() => navigate(`/organization/edit/${record.key}`)}><EditOutlined /></button>}
        </Space>
      ),
    },
  ];

  useEffect(() => {
    navStore.selectedKey = "6";
    setLoading(true);
    getOrganizations().then((res) => {
      const Organizations = res.map((x) => {
        const key = x.id;
        const name = x.name;
        return {
          key,
          name,
        };
      });
      setOrganizations(Organizations);
      setLoading(false);
    });
  }, [navStore]);

  return (
    <div className="Organization m-2">
      <div className="seperator">
        {!loading && ["admin"].indexOf(authStore.userRole) >= 0 && (
          <Button
            type="primary"
            style={{ float: "right" }}
            onClick={() => navigate("/organization/add")}
          >
            Create Organization
          </Button>
        )}
      </div>
      <h1>Organizations</h1>
      {loading && <Spin indicator={antIcon} />}
      {!loading && <Table dataSource={Organizations} columns={columns} />}
    </div>
  );
}

export default observer(Organization);
