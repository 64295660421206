import React, { useCallback, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import {
  LoadingOutlined,
} from "@ant-design/icons";
import { Button, Table, Spin } from "antd";

import { getProjects } from "../../utils/api";
import { timeSince } from "../../utils/DatetimeFormat";
import { useNavigate } from "react-router-dom";

import "./Projects.css";
import { toJS } from "mobx";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

function Projects({ authStore, navStore, projectStore }) {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [dataSource, setDataSource] = useState(toJS(projectStore.projects));

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (_, record) => {
        return (
          <span>
            <button className="link"
              onClick={() => {
                navigate(`/project/${record.id}`);
              }}
            >
              {record.name}
            </button>
          </span>
        );
      },
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Owner",
      dataIndex: "owner",
      key: "owner_id",
      render: (owner) => `${owner?.firstname} ${owner?.lastname}`,
    },
    {
      title: "Created",
      dataIndex: "created_at",
      key: "created_at",
      render: (date) => timeSince(new Date(date)) + " ago " ,
    },
  ];

  if(authStore && (authStore.userRole === "admin" || authStore.userRole === "user")) {
    columns.push({
      title: "Organization",
      dataIndex: "organization",
      key: "organization",
      render: (organization) => `${organization ? organization.name: ""}`,
    })
  };

  const fetchData = useCallback(async () => {
    if(dataSource.length > 0) {
      setLoading(false);
    }
    const projects = await getProjects();
    projects.forEach((x) => {
      x.key = x.id;
    });
    setDataSource(projects);
    projectStore.updateProjects(projects)
    setLoading(false);
  }, [dataSource.length, projectStore]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <div className="Projects">
      <div className="m-2">
        {loading ? (
          <Spin indicator={antIcon} />
        ) : (
          <div>
            <div className="Projects__headerbar">
              {dataSource.length > 0 && (
                <Button
                  className="Projects__header_button"
                  type="primary"
                  onClick={() => navigate("/project/add")}
                >
                  Create
                </Button>
              )}
            </div>
            <h1>Projects</h1>
            {dataSource.length === 0 ? (
              <div className="Projects__new">
                <div className="Project__new_img"></div>
                <div className="mt-2">
                  Look like you haven't created a project yet.
                </div>

                <Button
                  className="mt-1"
                  type="primary"
                  size="large"
                  onClick={() => navigate("/project/add")}
                >
                  Create a new Project
                </Button>
              </div>
            ) : (
              <Table
                dataSource={dataSource}
                columns={columns}
                pagination={{ pageSize: 50 }}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default observer(Projects);
