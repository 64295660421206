import { observer } from "mobx-react-lite";
import { useCallback, useEffect, useState } from "react";
import { Form, Select, Button, notification, Spin } from "antd";
import { LoadingOutlined, LeftOutlined } from "@ant-design/icons";
import { configureLanguage, getAllSupportedLanguage, getConfiguredLanguage } from "../../utils/api";
import { useNavigate, useParams } from "react-router-dom";
import "./AddProject.css";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const antIconWhite = (
  <LoadingOutlined style={{ color: "white", fontSize: 18 }} spin />
);
const { Option } = Select;

function ConfigureLanguage({ authStore }) {
	const [loading, setLoading] = useState(true);
	const [submitting, setSubmitting] = useState(false);
	const [error, setError] = useState("");
	const [languages, setLanguages] = useState([]);
	const [configLanguages, setConfigLanguages] = useState([]);
	const [form] = Form.useForm();
	let { id } = useParams();
	const navigate = useNavigate();

	const fetchData = useCallback(async () => {
		const languages = await getAllSupportedLanguage();
		const defaultLanguage = await getConfiguredLanguage(id);
		setLanguages(languages["Languages"])
		setConfigLanguages(defaultLanguage);
		const languageData = [];
		defaultLanguage?.map((data) => {
			languageData.push(data.language_code)
			return languageData
		})
		form.setFieldsValue({
			data: languageData
		})
		setLoading(false);
	}, [form, id]);

	useEffect(() => {
		fetchData();
	}, [fetchData]);

	const handleChange = (value) => {
		const languageData = [];
		value.map((languageCode) => {
			languageData.push({
				language_code: languageCode.trim(),
				language: languages.find(x=> x.LanguageCode === languageCode).LanguageName
			})
			return languageData
		})
		setConfigLanguages(languageData)
	}
	const onFinish = async () => {
		setError("");
		setSubmitting(true);
		try {
			await configureLanguage(id, configLanguages);
			notification.open({
				message: "Language Configured",
				description: "Language Configured for this project",
			});
			navigate(-1);
		} catch (e) {
			setSubmitting(false);
			setError(e.response.data.message);
		} finally {
			setSubmitting(false);
		}
	};

	return (
		<div className="AddProject">
			<div className="m-2">
				{loading ? (
					<Spin indicator={antIcon} />
				) : (
					<div className="AddProject__wrapper">
						<div className="AddProject__container">
							<Button
								className="ProjectPermission__container_back"
								type="text"
								onClick={() => navigate(-1)}
							>
								<LeftOutlined /> Back
							</Button>
							<h2>Configure Languages</h2>
							<Form
								name="basic"
								initialValues={{ remember: true }}
								autoComplete="off"
								layout="vertical"
								form={form}
								onFinish={onFinish}
							>
								<Form.Item
									className="AddProject__item"
									label="data"
									name="data"
								>
									<Select
										mode="multiple"
										style={{ width: '100%' }}
										onChange={handleChange}
										optionLabelProp="label"
									>
										{languages?.map((data) => {
											return <Option key={data.LanguageCode} value={data.LanguageCode}>{data.LanguageName} ( {data.LanguageCode} )</Option>
										})}
									</Select>
								</Form.Item>

								{error && <div className="error">{error}</div>}
								<Form.Item>
									<Button type="primary" htmlType="submit" block>
										{submitting && <Spin indicator={antIconWhite} />}
										{!submitting && <span> Configure</span>}
									</Button>
								</Form.Item>
							</Form>
						</div>
					</div>
				)}
			</div>
		</div>
	);
}

export default observer(ConfigureLanguage);
