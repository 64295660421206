import { observer } from "mobx-react-lite";
import { useState } from "react";
import { Form, Input, Button } from "antd";
import { Spin, notification } from "antd";
import { LoadingOutlined, LeftOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { createProject } from "../../utils/api";
import "./AddProject.css";

const antIcon = (
  <LoadingOutlined style={{ fontSize: 24, color: "white" }} spin />
);
const { TextArea } = Input;

function AddProject({ navStore, authStore }) {
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");

  const navigate = useNavigate();
  navStore.updateSelectedKey("4");

  const onFinish = async () => {
    setError("");
    setSubmitting(true);

    try {
      await createProject(name, description);
      notification.open({
        message: "Project Created",
        description: "Project Created Successfully",
      });
      navigate("/");
    } catch (e) {
      setSubmitting(false);
      setError(e.response.data.message);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className="AddProject">
      <div className="AddProject__wrapper">
        <div className="AddProject__container">
          <Button
            className="ProjectPermission__container_back"
            type="text"
            onClick={() => navigate(-1)}
          >
            <LeftOutlined /> Back
          </Button>
          <h2>Create a new project</h2>
          <Form
            name="basic"
            initialValues={{ remember: true }}
            autoComplete="off"
            layout="vertical"
            onFinish={onFinish}
          >
            <Form.Item
              className="AddProject__item"
              label="Name"
              name="name"
              rules={[
                { required: true, message: "Please enter a project name" },
              ]}
            >
              <Input onChange={(e) => setName(e.target.value)} placeholder="Project name" />
            </Form.Item>

            <Form.Item
              className="AddProject__item"
              label="Description"
              name="descriptoin"
              rules={[{ required: false}]}
            >
              <TextArea
                rows={2}
                onChange={(e) => setDescription(e.target.value)}
                placeholder="Description"
              />
            </Form.Item>

            {error && <div className="error">{error}</div>}
            <Form.Item>
              <Button type="primary" htmlType="submit" block>
                {submitting && <Spin indicator={antIcon} />}
                {!submitting && <span> Create</span>}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
}

export default observer(AddProject);
