import React, { useCallback, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import {
  LoadingOutlined,
  SettingOutlined,
  DeleteOutlined,
  CopyOutlined,
  ExclamationCircleOutlined,
  EyeOutlined,
  EditOutlined,
  MinusCircleOutlined,
  RetweetOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Button,
  Table,
  Spin,
  Dropdown,
  Modal,
  Form,
  Input,
  Menu,
  notification,
} from "antd";
import {
  getProject,
  deleteProject,
  getUrls,
  createUrl,
  updateProject,
  getSubUrls,
  deleteUrl,
  retry,
} from "../../utils/api";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

import "./ProjectDetail.css";
import { toJS } from "mobx";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const antIconWhite = (
  <LoadingOutlined style={{ color: "white", fontSize: 18 }} spin />
);
const { confirm } = Modal;

function ProjectDetail({ authStore, navStore, projectStore, params }) {
  const [submitting, setSubmitting] = useState(false);
  const [savingName, setSavingName] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [url, setUrl] = useState(false);
  const [language, setLanguage] = useState(false);
  const [baseUrl, setBaseUrl] = useState(null);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [project, setProject] = useState(toJS(projectStore.project));
  const [urls, setUrls] = useState(null);
  const [name, setName] = useState("");
  const [form] = Form.useForm();

  const onAddUrl = async () => {
    form.resetFields();
    setVisible(true);
    setUrl("");
    setBaseUrl(null);
  };

  const onAddSubUrl = async (record) => {
    form.resetFields();
    setBaseUrl(record);
    setVisible(true);
    setUrl("");
  };

  const onFinishAddingBaseUrl = async () => {
    try {
      await createUrl(id, url, false, null, null);
      await fetchData();
      setVisible(false);
      notification.open({
        message: "URL Added",
        description: "URL added sucessfully",
      });
    } catch (e) {
      notification.open({
        message: e.message ? e.message : "Error",
        description: e.response.data
          ? e.response.data.message
          : "Please try again",
      });
      setSubmitting(false);
    } finally {
      setSubmitting(false);
    }
  };

  const onFinishAddingSubUrl = async () => {
    try {
      await createUrl(id, url, true, baseUrl.id, language);
      await fetchData();
      setVisible(false);
      notification.open({
        message: "Alternate URL Added",
        description: "Alternate URL added sucessfully",
      });
    } catch (e) {
      notification.open({
        message: e.message ? e.message : "Error",
        description: e.response.data
          ? e.response.data.message
          : "Please try again",
      });
      setSubmitting(false);
    } finally {
      setSubmitting(false);
    }
  };

  const onFinish = async () => {
    setSubmitting(true);
    if (baseUrl) {
      await onFinishAddingSubUrl();
    } else {
      await onFinishAddingBaseUrl();
    }
  };

  const saveEdit = async () => {
    setSavingName(true);
    await updateProject(id, name, project.description, project.owner_id);
    setEditMode(false);
    project.name = name;
    await fetchData();
    setSavingName(false);
  };

  const menu = (
    <Menu
      onClick={(e) => {
        if (e.key === "copy") {
          onCopyScript();
        } else if (e.key === "delete") {
          showDeleteConfirm();
        } else if (e.key === "permission") {
          navigate(`/project/${project.id}/permission`);
        } else if (e.key === "omit") {
          navigate(`/omit-words/project/${project.id}`);
        } else if (e.key === "configure") {
          navigate(`/configure-language/project/${project.id}`);
        }
      }}
      items={[
        {
          label: "Copy script",
          key: "copy",
          icon: <CopyOutlined />,
        },
        {
          label: "Delete",
          key: "delete",
          icon: <DeleteOutlined />,
        },
        {
          label: "Omit Phrase",
          key: "omit",
          icon: <MinusCircleOutlined />,
        },
        {
          label: "Configure Language",
          key: "configure",
          icon: <EditOutlined />,
        },
        project &&
          (authStore.userRole === "org_admin" ||
            authStore.userRole === "admin" ||
            project.owner_id === authStore.user.id) && {
            label: "Permissions",
            key: "permission",
            icon: <EyeOutlined />,
          },
      ]}
    />
  );

  const columns = [
    {
      title: "Url",
      dataIndex: "url",
      key: "url",
      render: (_, record) => {
          if(record.base_url_id === null) {
            return <span>
              {record.url}
          </span>
          } else {
            return <span>
            <button
              className="link"
              onClick={() => {
                navigate(`/project/${record.project_id}/url/${record.id}/text`);
              }}
            >
              {record.url}
            </button>
          </span>
          }
      },
    },
    {
      title: "Locale",
      dataIndex: "language",
      key: "language",
      render: (_, record) => {
        if (record.children) {
          return <span>{record.children.length} locales</span>;
        } else {
          return <span>{record.language}</span>;
        }
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (_, record) => {
        return <div>{record.status}</div>;
      },
    },

    {
      title: "HTTP Status",
      dataIndex: "http_status_code",
      key: "http_status_code",
      render: (_, record) => {
        return <div>{record.http_status_code}</div>;
      },
    },
    {
      title: "Actions",
      dataIndex: "action",
      key: "action",
      render: (_, record) => {
        return (
          <div>
            <button
              className="link"
              onClick={() => showDeleteUrlConfirm(record.id)}
            >
              <DeleteOutlined />
            </button>

            <button
              className="link"
              onClick={() => onRetry(record.project_id, record.id, record.url)}
            >
              <RetweetOutlined /> Crawl
            </button>

            {record.children && (
              <button className="link" onClick={() => onAddSubUrl(record)}>
                <PlusOutlined /> Add Sub URL
              </button>
            )}
          </div>
        );
      },
    },
  ];

  let { id } = useParams();

  const onDeletProject = async (projectId) => {
    await deleteProject(projectId);
    navigate("/");
  };

  const onDeleteUrl = async (urlId) => {
    await deleteUrl(urlId);
    fetchData();
  };

  const showDeleteUrlConfirm = (urlId) => {
    confirm({
      title: "Are you sure you want to delete all urls?",
      icon: <ExclamationCircleOutlined />,
      content: "",
      okText: "Delete",
      okType: "danger",
      cancelText: "cancel",
      async onOk() {
        await onDeleteUrl(urlId);
      },
      onCancel() {},
    });
  };

  const onCopyScript = () => {
    const src = `${window.location.origin}/dist/app.bundle.js`;
    const text = `<script src="${src}" class="${id}" id="seoProjectId"></script>`;
    navigator.clipboard.writeText(text);
    notification.open({
      message: "Copied to Clipboard",
      description: text,
    });
  };

  const showDeleteConfirm = () => {
    confirm({
      title: "Are you sure delete this project?",
      icon: <ExclamationCircleOutlined />,
      content: "",
      okText: "Delete",
      okType: "danger",
      cancelText: "cancel",
      onOk() {
        onDeletProject(id);
      },
      onCancel() {},
    });
  };

  const onRetry = async (projectId, urlId, url) => {
    notification.open({
      message: "Crawling",
      description: "Crawling URL " + url,
    });
    await retry(projectId, urlId);
    setTimeout(() => fetchData(), 2000);
  };

  const fetchData = useCallback(async () => {
    const projectDetailPromise = getProject(id);
    const urlsPromise = getUrls(id);
    const projectDetail = await projectDetailPromise;
    const urls = await urlsPromise;
    setProject(projectDetail[0]);
    projectStore.updateProject(projectDetail[0]);
    setName(projectDetail[0].name);
    let urls_ = [];
    for (const url of urls) {
      const subUrls = await getSubUrls(id, url.id);
      urls_.push({ ...url, children: subUrls });
    }
    setUrls(urls_);
    setLoading(false);

    let poll = false;
    urls_.forEach((x) => {
      x.key = x.id;
      if (x.status === "in-progress" || x.status === "pending") {
        poll = true;
      }
      x.children.forEach((y) => {
        y.key = y.id;
        if (y.status === "in-progress" || y.status === "pending") {
          poll = true;
        }
      });
    });
    if (poll) {
      setTimeout(fetchData, 2000);
    }
  }, [id, projectStore]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <div className="ProjectDetail">
      <Modal
        visible={visible}
        title={
          baseUrl ? "Add an alternate URL for " + baseUrl.url : "Add a new URL"
        }
        onCancel={() => {
          setVisible(false);
        }}
        footer={null}
      >
        <Form
          name="basic"
          initialValues={{ remember: true }}
          autoComplete="off"
          layout="vertical"
          form={form}
          onFinish={onFinish}
        >
          <Form.Item
            className="AddProject__item"
            placeholder="URL"
            label="Url"
            name="url"
            rules={[{ required: true, message: "Please enter a url" }]}
          >
            <Input placeholder="URL" onChange={(e) => setUrl(e.target.value)} />
          </Form.Item>

          {baseUrl && (
            <Form.Item
              className="AddProject__item"
              placeholder="language"
              label="Language"
              name="language"
              rules={[{ required: true, message: "Please enter a language" }]}
            >
              <Input
                placeholder="Language"
                onChange={(e) => setLanguage(e.target.value)}
              />
            </Form.Item>
          )}

          <Form.Item style={{ marginBottom: "0" }}>
            <div style={{ textAlign: "right" }}>
              <Button type="primary" htmlType="submit">
                {submitting && <Spin indicator={antIconWhite} />}
                {!submitting && <span> Create</span>}
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Modal>

      <div>
        {loading ? (
          <Spin indicator={antIcon} />
        ) : (
          <div>
            <div className="Projects__headerbar">
              <Button
                type="primary"
                style={{ marginRight: "5px" }}
                onClick={onAddUrl}
                disabled={submitting}
              >
                Add Base URL
              </Button>
              <Dropdown overlay={menu}>
                <Button>
                  <SettingOutlined />
                </Button>
              </Dropdown>
            </div>
            {savingName && <Spin indicator={antIcon} />}
            {!savingName &&
              (!editMode ? (
                <h1>
                  {project.name}{" "}
                  <button className="link" onClick={() => setEditMode(true)}>
                    <EditOutlined />
                  </button>{" "}
                </h1>
              ) : (
                <h1>
                  <Input
                    defaultValue={name}
                    onChange={(e) => setName(e.target.value)}
                    onBlur={() => saveEdit()}
                  ></Input>
                </h1>
              ))}
            <Table
              dataSource={urls}
              columns={columns}
              pagination={{ pageSize: 50 }}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default observer(ProjectDetail);
