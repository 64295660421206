import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { Form, Input, Button } from "antd";
import { Spin, notification } from "antd";
import { LoadingOutlined, LeftOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import { getOrganization, createOrganization, updateOrganization } from "../../utils/api";
import "./AddOrganization.css";

const antIcon = (
  <LoadingOutlined style={{ fontSize: 24, color: "white" }} spin />
);

const antIconDark = (
  <LoadingOutlined style={{ fontSize: 24 }} spin />
);

function AddOrganization({ navStore, authStore }) {
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [organization, setOrganization] = useState(null);

  navStore.updateSelectedKey("2");

  let { id } = useParams();
  const editMode = id !== undefined;

  useEffect(()=> {
    const fetchData = async () => {
      const _organization = await getOrganization(id);
      setOrganization(_organization[0]);
      setName(_organization[0].name);
      setLoading(false);
    };
    if (id) {
      fetchData();
    }
  }, [id]);

  const onFinish = async () => {
    setError("");
    setSubmitting(true);

    try {
      if (editMode) {
        await updateOrganization(organization.id, name);
        notification.open({
          message: "Organization Updated",
          description: "Organization updated successfully",
        });
      } else {
        await createOrganization(name);
        notification.open({
          message: "Organization Added",
          description: "Organization added successfully",
        });
      }
      navigate("/organization");
    } catch (e) {
      setSubmitting(false);
      setError(e.response.data.message);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className="AddOrganization">
      <div className="AddOrganization__wrapper">
        <div className="AddOrganization__container">
          <Button
            className="ProjectPermission__container_back"
            type="text"
            onClick={() => navigate(-1)}
          >
            <LeftOutlined /> Back
          </Button>
          <h2>
            {editMode ? "Edit organization" : "Create a new organization"}
          </h2>
          {editMode && loading && <Spin indicator={antIconDark} />}

          {((editMode && !loading) || !editMode) && <Form
            name="basic"
            initialValues={{ remember: true, name: name }}
            autoComplete="off"
            layout="vertical"
            onFinish={onFinish}
          >
            <Form.Item
              className="AddOrganization__item"
              label="Name"
              name="name"
              rules={[{ required: true, message: "Please enter a name" }]}
            >
              <Input onChange={(e) => setName(e.target.value)} />
            </Form.Item>
            {error && <div className="error">{error}</div>}
            <Form.Item>
              <Button type="primary" htmlType="submit" block>
                {submitting && <Spin indicator={antIcon} />}
                {!submitting && <span> {editMode ? "Save" : "Create"}</span>}
              </Button>
            </Form.Item>
          </Form>}
        </div>
      </div>
    </div>
  );
}

export default observer(AddOrganization);
